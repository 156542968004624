import { Tour_Clicked_Index } from './guideWidgetClickAction';

interface GuideWidgetClickState {
  index: number;
}

const initialState: GuideWidgetClickState = {
  index: 10,
};

interface GuideWidgetClickAction {
  type: string;
  index: number;
}

function GuideWidgetClickReducer(
  state = initialState,
  action: GuideWidgetClickAction,
) {
  switch (action.type) {
    case Tour_Clicked_Index:
      return { ...state, index: action.index };
    default:
      return state;
  }
}

export default GuideWidgetClickReducer;
