import { Guide_Tour_Third_Step_Flag } from './tourGuideActions';

interface TourGuideState {
  flag: boolean;
}

const initialState: TourGuideState = {
  flag: false,
};

interface TourGuideAction {
  type: string;
  flag: boolean;
}

function tourGuideReducer(state = initialState, action: TourGuideAction) {
  switch (action.type) {
    case Guide_Tour_Third_Step_Flag:
      return { ...state, flag: action.flag };
    default:
      return state;
  }
}

export default tourGuideReducer;
